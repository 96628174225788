import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router";
import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { colors } from "./assets";
import {
	Footer,
	NavBar,
	About,
	Home,
	Insight,
	Product,
	InsightPdf,
	Blog,
	SingleBlog,
	Shortlink,
} from "./components";

const routes = [
	{
		path: "/",
		element: <Home />,
		showFooterDisclaimer: false,
		showNavbar: true,
	},
	{
		path: "/home",
		element: <Navigate to="/" />,
		showFooterDisclaimer: false,
		showNavbar: true,
	},
	{
		path: "about-us",
		element: <About />,
		showFooterDisclaimer: false,
		showNavbar: true,
	},
	{
		path: "/product/:id",
		element: <Product />,
		showFooterDisclaimer: false,
		showNavbar: true,
	},
	{
		path: "insight",
		element: <Insight />,
		showFooterDisclaimer: false,
		showNavbar: true,
	},
	{
		path: "insight/:id",
		element: <InsightPdf />,
		showFooterDisclaimer: false,
		showNavbar: true,
	},
	{
		path: "/blog",
		element: <Blog />,
		showFooterDisclaimer: false,
		showNavbar: true,
	},
	{
		path: "/blog/:id",
		element: <SingleBlog />,
		showFooterDisclaimer: false,
		showNavbar: true,
	},
	{
		path: "/:shortlink",
		element: <Shortlink />,
		showFooterDisclaimer: false,
		showNavbar: false,
	},
];

//define routes that use light theme
const lightThemRoutes = ["/blog"]

const THEME = createTheme({
	palette: {
		mode: "dark",
		secondary: { main: colors.secondary },
		background: { default: colors.gray6 },
	},
	typography: { fontFamily: "TT Commons" },
	
	components:{
		MuiLink: {
			styleOverrides:{
				root:{
					color: "white",
				}
			}
		}
	}
});

const lightTheme = createTheme({
	palette: {
		mode: "light",
		secondary: { main: colors.secondary },
		primary: { 
			light: colors.primary, 
			main: "#03559A", 
			contrastText: "#ffffff" 
		},
		background: { default: "white" },
	},
	typography: { 
		fontFamily: "TT Commons" 
	},
	components:{
		MuiLink: {
			styleOverrides:{
				root:{
					color: "black" ,
				}
			}
		}
	}
})


function App() {
	const companyName = process.env.REACT_APP_COMPANY;
	const location = useLocation();
	const { pathname } = location;

	const desiredTheme = lightThemRoutes.map(route => {
		if(pathname.includes(route)){
			return lightTheme
		}
		return THEME
	})[0]

	console.log(desiredTheme)

	return (
		<HelmetProvider>
			<Helmet>
				{companyName === "HPAM" && <title>Henan Asset Management</title>}
				{companyName === "HPS" && <title>Henan Sekuritas</title>}
			</Helmet>

			<ThemeProvider theme={THEME}>
				<Routes>
					{routes?.map((route, index) => {
						return(
							<Route
								key={index}
								path={route?.path}
								element={
									<ThemeProvider theme={desiredTheme}>
										<CssBaseline />
										<Box sx={{ backgroundColor: "background.default", colorScheme: desiredTheme.palette.mode}}>
											<div style={{ minHeight: "100vh" }}>
												{route?.showNavbar && <NavBar />}
												{route?.element}
											</div>

											<Footer showDisclaimer={route?.showFooterDisclaimer} />
										</Box>
									</ThemeProvider>

								}
							/>
						)
					})}
				</Routes>
			</ThemeProvider>
		</HelmetProvider>
	);
}

export default App;
