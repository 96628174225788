/* eslint-disable no-template-curly-in-string */
import { Container, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
	calculateChartData,
	calculateProductInfo,
	fundType,
} from "../../services";
import axios from "axios";
import { apiUrls } from "../../services/apiUrls";
import { InfoText, StockChart } from "../molecules";
import { DocDownloadButton } from "../atoms";
import { InvestmentCalculator } from "../organisms";
import { useSelector } from "react-redux";

export const Product = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const { id } = useParams();

	const locale = useSelector((state) => state.locale);

	// CALCULATE PRODUCT DATA
	const [productData, setProductData] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const response = await axios.get(
				apiUrls.product.getProduct.replace("${id}", id)
			);
			const data = response?.data?.data?.produk;
			setProductData(data);
		};

		fetchData();
	}, [id]);

	// CALCULATE NAV
	const [isLoadingChart, setIsLoadingChart] = useState(true);
	const [timeframe, setTimeframe] = useState("1Y");
	const [navData, setNavData] = useState();
	const [navProsentase, setNavProsentase] = useState();

	useEffect(() => {
		const fetchData = async () => {
			setIsLoadingChart(true);

			const response = await axios.get(
				apiUrls.product.getProductChart
					.replace("${timeframe}", timeframe)
					.replace("${id}", id)
			);
			const navData = response?.data?.data?.kinerja;
			setNavData(navData);
			setNavProsentase(navData?.[navData?.length - 1]?.persentase_nav);

			setIsLoadingChart(false);
		};

		fetchData();
	}, [timeframe, id]);

	// GENERATE CHART DATA
	const chartData = useMemo(
		() => calculateChartData({ data: navData }),
		[navData]
	);

	// GENERATE PRODUCT INFO
	const productInfo = useMemo(
		() => calculateProductInfo({ productData, navProsentase, timeframe }),
		[productData, navProsentase, timeframe]
	);

	return (
		<Container maxWidth="lg">
			<Stack
				spacing={!isMobile ? "100px" : "60px"}
				style={{ margin: !isMobile ? "100px 0" : "60px 0" }}
			>
				{/* CHART HEADER INFORMATION */}
				<Grid container gap="32px" justifyContent="space-between">
					{productInfo?.topInfo?.map((item, index) => (
						<Grid item key={index}>
							<InfoText
								subtitle={item?.subtitle}
								title={item?.title}
								leading={item?.leading}
								trailing={item?.trailing}
							/>
						</Grid>
					))}
				</Grid>

				{/* CHART */}
				<StockChart
					data={chartData}
					isLoading={isLoadingChart}
					timeframe={timeframe}
					setTimeframe={setTimeframe}
				/>

				{/* CHART FOOTER INFORMATION */}
				<Grid container gap="32px" justifyContent="space-between">
					{productInfo?.bottomInfo?.map((item, index) => (
						<Grid item key={index}>
							<InfoText
								subtitle={item?.subtitle}
								title={
									locale === "en" && item?.subtitle === "Fund Type"
										? fundType[item?.title]
										: item?.title
								}
								leading={item?.leading}
								trailing={item?.trailing}
							/>
						</Grid>
					))}
				</Grid>

				{/* INVESTMENT CALCULATOR */}
				<InvestmentCalculator cagr={productData?.cagr} />

				{/* DOCUMENT DOWNLOAD BUTTONS */}
				<Stack
					spacing={2}
					direction={{ xs: "column", sm: "row" }}
					style={{ width: "100%" }}
				>
					<DocDownloadButton
						label="Prospectus"
						url={productData?.link_prospektus}
					/>

					<DocDownloadButton
						label="Fund Fact Sheet"
						url={productData?.link_ffs}
					/>
				</Stack>
			</Stack>
		</Container>
	);
};
